import React, { Fragment } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"
import Layout from "../components/NewLayout"
import styled from "styled-components"
import { Toggle } from "Utilities"
import { Modal } from "Elements"
import { colors } from "Utilities"
import StyledTeamPageBanner from "../components/teamPageBg"
import DebRobbinsGif from "../gifs/DebRobbinsGif.gif"
import SamKingGif from "../gifs/SamKingGif.gif"
import SallyWildingGif from "../gifs/SallyWildingGif.gif"
import ConnieClailGif from "../gifs/ConnieClailGif.gif"


const readStyle = {
  lineHeight: "2rem",
  textDecoration: "underline",
  cursor: "pointer",
  background: "transparent",
  border: "none",
};

const modalText = {
  fontSize: "0.9rem",
};


export default function TeamPage ({ data }) {

  return (
  
    <Layout>
      <SEO title="Home" />
      
      <Container>
        <StyledTeamPageBanner>
          <TitleWrapper>
            <h1>Meet the team.</h1>
            <p>At Storm Hair and Beauty, we are exceptionally lucky to have such a talented team. We take pride in our professionalism, along with our passion for creativity and care. We ensure that our clients’ needs are always our first priority and enjoy making their hair and beauty dreams a reality.</p>
          </TitleWrapper>
        </StyledTeamPageBanner>
        <TeamItemWrapper>
          <Scene>
            <TeamCard>
              <CardFront>
                <Img fluid={data.DebRobbins.childImageSharp.fluid} alt="Debs' portrait, staff member" />
              </CardFront>
              <CardBack>
                <img src={DebRobbinsGif} alt="Deborah Robbins Gif" />
              </CardBack>
            </TeamCard>
            <StaffDetails>
                <TitleStyles>Deborah <br/>(Owner) Director</TitleStyles>
                <p>
                  I started hairdressing as soon as I left hairdressing college, working in various salons in Wells. I opened Storm with another fab hairdresser Vicki Coleman, in Street in 2006...
                  <br/>
                  <Toggle>
              {({ on, toggle }) => (
                <Fragment>
                  <button onClick={toggle} style={readStyle}>Read more</button>
                  <Modal on={on} toggle={toggle}>
                    <ModalScene>
                      <TeamCard>
                        <CardFront>
                          <Img fluid={data.DebRobbins.childImageSharp.fluid} alt="Debs' portrait, staff member" />
                        </CardFront>
                        <CardBack>
                          <img src={DebRobbinsGif} alt="Deborah Robbins Gif" />
                        </CardBack>
                      </TeamCard>
                      <StaffDetails>
                          <TitleStyles>Deborah <br/>(Owner) Director</TitleStyles>
                          <p style={modalText}>
                            I started hairdressing as soon as I left hairdressing college, working in various salons in Wells. I opened Storm with another fabulous hairdresser Vicki Coleman in 2006. I love this shop: we concentrate on using only eco-friendly; responsibly sourced and cruelty free products - recycling as much as possible. I have had great fun keeping up my knowledge of new and exciting concepts within the industry. We now use Davines, which is my dream product and holds all the same values as I have for the environment. It also helps me expand all aspects of Hairdressing, the thing I love to do!
                          </p>
                      </StaffDetails>
                    </ModalScene>
                  </Modal>
                </Fragment>
              )}
            </Toggle>
                </p>
            </StaffDetails>
          </Scene>
          <Scene>
            <TeamCard>
              <CardFront>
                <Img fluid={data.SallyWilding.childImageSharp.fluid} alt="Sallys' portrait, staff member" />
              </CardFront>
              <CardBack>
                <img src={SallyWildingGif} alt="Sally Wilding Gif" />
              </CardBack>
            </TeamCard>
            <StaffDetails>
              <TitleStyles>Sally <br/>Director</TitleStyles>
              <p>I have been in the industry for over 25 years. I trained locally at Strode college and worked in both salons, all within the local area of Wells, Glastonbury and Street...
              <br/>
              <Toggle>
                {({ on, toggle }) => (
                  <Fragment>
                    <button onClick={toggle} style={readStyle}>Read more</button>
                    <Modal on={on} toggle={toggle}>
                      <ModalScene>
                        <TeamCard>
                          <CardFront>
                            <Img fluid={data.SallyWilding.childImageSharp.fluid} alt="Sallys' portrait, staff member" />
                          </CardFront>
                          <CardBack>
                            <img src={SallyWildingGif} alt="Sally Wilding Gif" />
                          </CardBack>
                        </TeamCard>
                        <StaffDetails>
                            <TitleStyles>Sally <br/>Director</TitleStyles>
                            <p style={modalText}>
                              I have been in the industry for over 25 years. I trained locally at Strode college and worked in both salons and mobile, all within the local area of Wells, Glastonbury and Street. I also owned my own salon for 17 years. Being a hairstylist was something I always wanted to do, as it goes with my creative tendencies - planning, designing, and creating a finished piece - be it hair, fabric or a room! I love meeting and talking to people and creating hairstyles to suit them and their lifestyle. I enjoy all the aspects of my role, which includes colouring and perming, but the cut and finish for me is the pièce de résistance!
                            </p>
                        </StaffDetails>
                      </ModalScene>
                    </Modal>
                  </Fragment>
                )}
              </Toggle>
              </p>
            </StaffDetails>
          </Scene>
          
          <Scene>
            <TeamCard>
              <CardFront>
                <Img fluid={data.ConnieClail.childImageSharp.fluid} alt="Connies' portrait, staff member" />
              </CardFront>
              <CardBack>
                <img src={ConnieClailGif} alt="Connie Clail Gif" />
              </CardBack>
            </TeamCard>
            <StaffDetails>
              <TitleStyles>Connie <br/>Senior Hair Stylist</TitleStyles>
              <p>I started my hairdressing journey when I was 14 years old. I worked as a Saturday girl and worked in the school holiday. I have only ever had a hairdressing...
              <br/>
              <Toggle>
                {({ on, toggle }) => (
                  <Fragment>
                    <button onClick={toggle} style={readStyle}>Read more</button>
                    <Modal on={on} toggle={toggle}>
                      <ModalScene>
                        <TeamCard>
                          <CardFront>
                            <Img fluid={data.ConnieClail.childImageSharp.fluid} alt="Connies' portrait, staff member" />
                          </CardFront>
                          <CardBack>
                            <img src={ConnieClailGif} alt="Connie Clail Gif" />
                          </CardBack>
                        </TeamCard>
                        <StaffDetails>
                            <TitleStyles>Connie <br/>Senior Hair Stylist</TitleStyles>
                            <p style={modalText}>
                              I started my hairdressing journey when I was 14 years old. I worked as a Saturday girl, which included working throughout the school holidays. I have only ever had a hairdressing job, as I always knew it was what I wanted to do. I have been working in the hairdressing industry for over 10 years now. I love everything about hairdressing, but my favourite things are colouring and having a good catch up with my lovely clients; that’s what I look forward to.
                            </p>
                        </StaffDetails>
                      </ModalScene>
                    </Modal>
                  </Fragment>
                )}
              </Toggle>
              </p>
            </StaffDetails>
          </Scene>
          <Scene>
            <TeamCard>
              <CardFront>
                <Img fluid={data.SamKing.childImageSharp.fluid} alt="Sam Ks' portrait, staff member" />
              </CardFront>
              <CardBack>
                <img src={SamKingGif} alt="Sam King Gif" />
              </CardBack>
            </TeamCard>
            <StaffDetails>
              <TitleStyles>Sam K <br/>Senior Beauty Therapist</TitleStyles>
              <p>I started my beauty journey training at Kendal college in Cumbria and quickly landed my dream job in a hotel spa in the middle of the Lake District. My passion...
              <br/>
              <Toggle>
              {({ on, toggle }) => (
                <Fragment>
                  <button onClick={toggle} style={readStyle}>Read more</button>
                  <Modal on={on} toggle={toggle}>
                    <ModalScene>
                      <TeamCard>
                        <CardFront>
                          <Img fluid={data.SamKing.childImageSharp.fluid} alt="Sam Ks' portrait, staff member" />
                        </CardFront>
                        <CardBack>
                          <img src={SamKingGif} alt="Sam King Gif" />
                        </CardBack>
                      </TeamCard>
                      <StaffDetails>
                          <TitleStyles>Sam K <br/>Senior Beauty Therapist</TitleStyles>
                          <p style={modalText}>
                            I started my beauty journey training at Kendal college in Cumbria and quickly landed my dream job in a hotel spa in the middle of the Lake District. My passion started off with the love of all things nails, which lead me to love all things beauty. My specialist treatments are waxing (personal and normal); gel nails and nail enhancements - my favourite thing to do is creating a beautiful set of nails. Bringing the clients nail ideas to life is definitely a highlight the day. I have over 10 years experience in the beauty industry and 7 of those years have been with Storm Hair and Beauty, since relocating to the area in 2012. I love seeing my clients for our monthly catch ups, doing their beauty treatments is just a bonus!
                          </p>
                      </StaffDetails>
                    </ModalScene>
                  </Modal>
                </Fragment>
              )}
            </Toggle>
              </p>
            </StaffDetails>
          </Scene>
          
          
        </TeamItemWrapper>
      </Container>
      
    </Layout>
    

  )
}


export const query = graphql`
  query Team {
    DebRobbins: file(relativePath: {eq: "DebRobbins1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    SamKing: file(relativePath: {eq: "SamKing1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    SamCompton: file(relativePath: {eq: "SamCompton1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    SallyWilding: file(relativePath: {eq: "SallyWilding1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    HollieWatson: file(relativePath: {eq: "HollieWatson1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    CharlyWalpole: file(relativePath: {eq: "CharlyWalpole1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ConnieClail: file(relativePath: {eq: "ConnieClail1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Georgina: file(relativePath: {eq: "Georgina1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 3rem;
  max-width: 500px;
  p{
    text-align: center;
  }
  @media (max-width: 769px) {
    margin-top: 3rem;
  }
  @media (max-width: 469px) {
    margin-top: 8rem;
  }
`

const Container = styled.div`
    width: 100%;
    padding: 1rem 4rem;
    @media (max-width: 769px) {
      padding: 1rem 3rem;
    }
    @media (max-width: 469px) {
      padding: 1rem 1rem;
    }
`
const TeamItemWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, auto));
  grid-gap: 2.5rem;
  margin-top: 1rem;
`
const Scene = styled.div`
  background: ${colors.lightGrey};
  box-shadow: 0 0 5px ${colors.black};
  padding: 10px;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 10px;
  
  align-content: start;
  @media (max-width: 469px) {
    grid-template-rows: repeat(auto-fit, minmax(180px, 1fr));
  }
`

const TeamCard = styled.div`
  width: 100%;
  padding-bottom: 100%;
  position: relative;

    
`

const CardFront = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  backface-visibility: hidden;
  transition: .3s;
  ${TeamCard}:hover & {
    transform: rotateY(-180deg);
  }
`
const CardBack = styled.div`
  overflow: hidden;
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  backface-visibility: hidden;
  transition: .3s;
  ${TeamCard}:hover & {
    transform: rotateY(0);
  }
    img {
      bottom: 0;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }
    
`

const StaffDetails = styled.div`
    p{
      line-height: 1.1rem;
    }
`;

const TitleStyles = styled.h2`
  color: ${colors.black};
  font-weight: lighter;
  font-size: 1rem;
  line-height: 1.5rem;
`;

const ModalScene = styled.div`
  max-width: 800px;
  background: ${colors.lightGrey};
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, auto));
  grid-gap: 10px;
  
  align-content: start;
  padding: 1.5rem;
  
`



